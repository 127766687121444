<template>
  <div class="admin-wrap">
    <AdminMenu active-item="overall-report" />
    <div class="admin-main">
      <AdminHeader title="總體報表" />
      <div class="overall-report">
        <div class="overall-report__date-picker">
          <input type="date" v-model="start">～<input type="date" v-model="end">
        </div>
        <p class="overall-report__total">案件總數：{{total}}</p>
        <div class="overall-report__ranking">
          <div class="overall-report__group">
            <h3>服務排行</h3>
            <table class="overall-report__ranking-table">
              <tbody>
                <tr v-for="(rank, index) in treatmentRankingPage.content" :key="index">
                  <td>{{treatmentRank(index)}}</td>
                  <td>{{rank.name}}</td>
                  <td>{{rank.score}}</td>
                </tr>
              </tbody>
            </table>
            <div class="admin-pagination">
              <button class="admin-pagination__btn-prev" :class="{ 'is-disable': treatmentRankingPage.first }" @click="onTreatmentRankingPrev">上一頁</button>
              <span class="admin-pagination__current">{{pageTreatmentRanking}}</span>
              <span class="admin-pagination__page">/ {{treatmentRankingPage.totalPages}}頁</span>
              <button class="admin-pagination__btn-next" :class="{ 'is-disable': treatmentRankingPage.last }" @click="onTreatmentRankingNext">下一頁</button>
            </div>
          </div>
          <div class="overall-report__group">
            <h3>醫師排行</h3>
            <table class="overall-report__ranking-table">
              <tbody>
                <tr v-for="(rank, index) in doctorRankingPage.content" :key="index">
                  <td>{{doctorRank(index)}}</td>
                  <td>{{rank.name}}</td>
                  <td>{{rank.score}}</td>
                </tr>
              </tbody>
            </table>
            <div class="admin-pagination">
              <button class="admin-pagination__btn-prev" :class="{ 'is-disable': doctorRankingPage.first }" @click="onDoctorRankingPrev">上一頁</button>
              <span class="admin-pagination__current">{{pageDoctorRanking}}</span>
              <span class="admin-pagination__page">/ {{doctorRankingPage.totalPages}}頁</span>
              <button class="admin-pagination__btn-next" :class="{ 'is-disable': doctorRankingPage.last }" @click="onDoctorRankingNext">下一頁</button>
            </div>
          </div>
          <div class="overall-report__group">
            <h3>VIP排行</h3>
            <table class="overall-report__ranking-table">
              <tbody>
                <tr v-for="(rank, index) in vipRankingPage.content" :key="index">
                  <td>{{vipRank(index)}}</td>
                  <td>{{rank.name}}</td>
                  <td>{{rank.score}}</td>
                </tr>
              </tbody>
            </table>
            <div class="admin-pagination">
              <button class="admin-pagination__btn-prev" :class="{ 'is-disable': vipRankingPage.first }" @click="onVIPRankingPrev">上一頁</button>
              <span class="admin-pagination__current">{{pageVIPRanking}}</span>
              <span class="admin-pagination__page">/ {{vipRankingPage.totalPages}}頁</span>
              <button class="admin-pagination__btn-next" :class="{ 'is-disable': vipRankingPage.last }" @click="onVIPRankingNext">下一頁</button>
            </div>
          </div>
        </div>
        <div class="overall-report__daily-work">
          <div class="overall-report__group">
            <h3>每日作業紀錄</h3>
            <table class="overall-report__daily-work-table">
              <thead>
                <tr>
                  <td>日期</td>
                  <td>案件數量</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="dailyCount in dailyCountPage.content" :key="dailyCount">
                  <td>{{dailyCount.date}}</td>
                  <td>{{dailyCount.count}}</td>
                </tr>
              </tbody>
            </table>
            <div class="admin-pagination">
              <button class="admin-pagination__btn-prev" :class="{ 'is-disable': dailyCountPage.first }" @click="onDailyCountPrev">上一頁</button>
              <span class="admin-pagination__current">{{pageDailyCount}}</span>
              <span class="admin-pagination__page">/ {{dailyCountPage.totalPages}}頁</span>
              <button class="admin-pagination__btn-next" :class="{ 'is-disable': dailyCountPage.last }" @click="onDailyCountNext">下一頁</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import { formatDate } from '../../utils/utils'
import {
  apiGetTreatmentRanking,
  apiGetDoctorRanking,
  apiGetVIPRanking,
  apiGetDailyCount,
  apiGetTotal
} from '@/api/v1/admin'

export default {
  name: 'OverallReport',
  components: { AdminMenu, AdminHeader },
  computed: {
    dateRange () {
      return [this.start, this.end]
    }
  },
  watch: {
    dateRange () {
      if (this.start && this.end) {
        this.fetchData()
      }
    },
    pageTreatmentRanking () {
      this.fetchTreatmentRanking()
    },
    pageDoctorRanking () {
      this.fetchDoctorRanking()
    },
    pageVIPRanking () {
      this.fetchVIPRanking()
    },
    pageDailyCount () {
      this.fetchDailyCount()
    }
  },
  created () {
    const today = new Date()
    this.start = formatDate(today)
    this.end = formatDate(new Date(today.setDate(today.getDate() + 1)))
    // this.fetchData()
  },
  data () {
    return {
      start: '',
      end: '',
      total: 0,
      treatmentRankingPage: {
        content: [],
        number: 0,
        totalPages: 0,
        first: true,
        last: true
      },
      doctorRankingPage: {
        content: [],
        number: 0,
        totalPages: 0,
        first: true,
        last: true
      },
      vipRankingPage: {
        content: [],
        number: 0,
        totalPages: 0,
        first: true,
        last: true
      },
      dailyCountPage: {
        content: [],
        number: 0,
        totalPages: 0,
        first: true,
        last: true
      },
      pageTreatmentRanking: 1,
      pageDoctorRanking: 1,
      pageVIPRanking: 1,
      pageDailyCount: 1,
      pageSize: 10
    }
  },
  methods: {
    fetchData () {
      this.fetchTreatmentRanking()
      this.fetchDoctorRanking()
      this.fetchVIPRanking()
      this.fetchDailyCount()
      this.fetchTotal()
    },
    fetchTreatmentRanking () {
      const params = {
        start: this.start,
        end: this.end,
        page: this.pageTreatmentRanking,
        size: this.pageSize
      }
      apiGetTreatmentRanking(params).then(data => {
        console.log(data)
        if (!data.error) {
          this.treatmentRankingPage = data
        }
      })
    },
    onTreatmentRankingPrev () {
      this.pageTreatmentRanking--
    },
    onTreatmentRankingNext () {
      this.pageTreatmentRanking++
    },
    // doctor
    fetchDoctorRanking () {
      const params = {
        start: this.start,
        end: this.end,
        page: this.pageDoctorRanking,
        size: this.pageSize
      }
      apiGetDoctorRanking(params).then(data => {
        console.log(data)
        if (!data.error) {
          this.doctorRankingPage = data
        }
      })
    },
    onDoctorRankingPrev () {
      this.pageDoctorRanking--
    },
    onDoctorRankingNext () {
      this.pageDoctorRanking++
    },
    // vip
    fetchVIPRanking () {
      const params = {
        start: this.start,
        end: this.end,
        page: this.pageVIPRanking,
        size: this.pageSize
      }
      apiGetVIPRanking(params).then(data => {
        console.log(data)
        if (!data.error) {
          this.vipRankingPage = data
        }
      })
    },
    onVIPRankingPrev () {
      this.pageVIPRanking--
    },
    onVIPRankingNext () {
      this.pageVIPRanking++
    },
    // daily count
    fetchDailyCount () {
      const params = {
        start: this.start,
        end: this.end,
        page: this.pageDailyCount,
        size: this.pageSize
      }
      apiGetDailyCount(params).then(data => {
        console.log(data)
        if (!data.error) {
          this.dailyCountPage = data
        }
      })
    },
    onDailyCountPrev () {
      this.pageDailyCount--
    },
    onDailyCountNext () {
      this.pageDailyCount++
    },
    // total count
    fetchTotal () {
      const params = {
        start: this.start,
        end: this.end
      }
      apiGetTotal(params).then(data => {
        if (!data.error) {
          this.total = data.count
        }
      })
    },
    // 計算排名數字
    treatmentRank (index) {
      return this.treatmentRankingPage.number * this.pageSize + index + 1
    },
    doctorRank (index) {
      return this.doctorRankingPage.number * this.pageSize + index + 1
    },
    vipRank (index) {
      return this.vipRankingPage.number * this.pageSize + index + 1
    },
    dailyCountRank (index) {
      return this.dailyCountPage.number * this.pageSize + index + 1
    }
  }
}
</script>

<style>

</style>
